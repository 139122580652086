import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import "./404.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="not-found">
      <div className="contentWrapper">
          <h2>Oops :-(</h2>
          <p>Looks like theres nothing here</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
